var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-reminder"},[_c('van-form',{ref:"loginForm",on:{"submit":_vm.submit}},[_c('div',{staticClass:"new-reminder-content"},[_c('div',{staticClass:"block case-info"},[_c('div',{staticClass:"block-title"},[_vm._v("案件信息")]),_c('div',{staticClass:"kv-info-wrp"},_vm._l(([
              {
                label: '订单编号',
                value: _vm.form.bizNo,
              },
              {
                label: '客户名称',
                value: _vm.form.customerName,
              },
            ]),function(item,index){return _c('div',{key:index,staticClass:"kv"},[_c('div',{staticClass:"k"},[_vm._v(_vm._s(item.label)+"：")]),_c('div',{staticClass:"v"},[_vm._v(_vm._s(item.value || "-"))])])}),0)]),_c('div',{staticClass:"block somn"},[_c('div',{staticClass:"block-title"},[_vm._v("催记信息")]),_c('van-field',{attrs:{"label":"联系人姓名","placeholder":"请输入","input-align":"right","required":"","maxlength":"32","rules":_vm.rules.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('van-field',{attrs:{"label":"联系电话","placeholder":"请输入","maxlength":"11","input-align":"right","required":"","rules":_vm.rules.mobile},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('van-field',{attrs:{"label":"联系人关系","placeholder":"请选择","rules":_vm.rules.relationshipName,"readonly":"","required":"","is-link":"","input-align":"right"},on:{"click":function($event){return _vm.$set(_vm.form, 'relationshipShow', true)}},model:{value:(_vm.form.relationshipName),callback:function ($$v) {_vm.$set(_vm.form, "relationshipName", $$v)},expression:"form.relationshipName"}}),_c('PickEnum',{attrs:{"show":_vm.form.relationshipShow,"columns":_vm.relationshipList.map((it) => ({
              label: it.displayName,
              value: it.value,
            }))},on:{"update:show":function($event){return _vm.$set(_vm.form, "relationshipShow", $event)},"handleConfirm":(v) => {
              _vm.$set(_vm.form, 'relationshipName', v.label);
              _vm.$set(_vm.form, 'relationship', v.value);
            }}}),_c('van-field',{attrs:{"label":"催收结果","placeholder":"请选择","rules":_vm.rules.collectionResultName,"readonly":"","required":"","is-link":"","input-align":"right"},on:{"click":function($event){return _vm.$set(_vm.form, 'collectionResultShow', true)}},model:{value:(_vm.form.collectionResultName),callback:function ($$v) {_vm.$set(_vm.form, "collectionResultName", $$v)},expression:"form.collectionResultName"}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.form.collectionResultShow),callback:function ($$v) {_vm.$set(_vm.form, "collectionResultShow", $$v)},expression:"form.collectionResultShow"}},[_c('van-cascader',{attrs:{"field-names":{
              text: 'collectionResultValue',
              value: 'collectionResultKey',
              children: 'childs',
            },"options":_vm.resultListFilterChilds},on:{"finish":(v) => {
                _vm.$set(
                  _vm.form,
                  'collectionResultName',
                  v.selectedOptions[v.tabIndex].collectionResultValue
                );
                _vm.$set(
                  _vm.form,
                  'collectionResultKey',
                  v.selectedOptions[v.tabIndex].collectionResultKey
                );
                _vm.$set(_vm.form, 'collectionResultShow', false);
              },"close":function($event){return _vm.$set(_vm.form, 'collectionResultShow', false)}}})],1),_c('van-field',{attrs:{"label":"是否结清","placeholder":"请选择","rules":_vm.rules.settleStatus,"required":"","readonly":"","is-link":"","input-align":"right"},on:{"click":function($event){return _vm.$set(_vm.form, 'settleStatusShow', true)}},model:{value:(_vm.form.settleStatus),callback:function ($$v) {_vm.$set(_vm.form, "settleStatus", $$v)},expression:"form.settleStatus"}}),_c('PickEnum',{attrs:{"show":_vm.form.settleStatusShow,"columns":['未结清', '已结清', '已还期款'].map((it) => ({
              label: it,
              value: it,
            }))},on:{"update:show":function($event){return _vm.$set(_vm.form, "settleStatusShow", $event)},"handleConfirm":(v) => _vm.$set(_vm.form, 'settleStatus', v.value)}}),_c('van-field',{attrs:{"label":"还款意愿","placeholder":"请选择","rules":_vm.rules.repaymentPossibility,"readonly":"","required":"","is-link":"","input-align":"right"},on:{"click":function($event){return _vm.$set(_vm.form, 'repaymentPossibilityShow', true)}},model:{value:(_vm.form.repaymentPossibilityStr),callback:function ($$v) {_vm.$set(_vm.form, "repaymentPossibilityStr", $$v)},expression:"form.repaymentPossibilityStr"}}),_c('van-field',{attrs:{"label":"诉讼建议","placeholder":"请选择","rules":_vm.rules.legalSuggestion,"readonly":"","required":"","is-link":"","input-align":"right"},on:{"click":function($event){return _vm.$set(_vm.form, 'legalSuggestionShow', true)}},model:{value:(_vm.form.legalSuggestionStr),callback:function ($$v) {_vm.$set(_vm.form, "legalSuggestionStr", $$v)},expression:"form.legalSuggestionStr"}}),_c('PickEnum',{attrs:{"show":_vm.form.repaymentPossibilityShow,"columns":_vm.repaymentPossibilityEnum.map((it) => ({
              label: it.displayName,
              value: it.value,
            }))},on:{"update:show":function($event){return _vm.$set(_vm.form, "repaymentPossibilityShow", $event)},"handleConfirm":(v) => {
              _vm.$set(_vm.form, 'repaymentPossibilityStr', v.label);
              _vm.$set(_vm.form, 'repaymentPossibility', v.value);
            }}}),_c('PickEnum',{attrs:{"show":_vm.form.legalSuggestionShow,"columns":_vm.legalSuggestionEnum.map((it) => ({
              label: it.displayName,
              value: it.value,
            }))},on:{"update:show":function($event){return _vm.$set(_vm.form, "legalSuggestionShow", $event)},"handleConfirm":(v) => {
              _vm.$set(_vm.form, 'legalSuggestionStr', v.label);
              _vm.$set(_vm.form, 'legalSuggestion', v.value);
            }}}),_c('van-field',{attrs:{"label":"是否继续催收","placeholder":"请选择","rules":_vm.rules.continueCollectStatus,"readonly":"","is-link":"","input-align":"right"},on:{"click":function($event){return _vm.$set(_vm.form, 'continueCollectStatusShow', true)}},model:{value:(_vm.form.continueCollectStatus),callback:function ($$v) {_vm.$set(_vm.form, "continueCollectStatus", $$v)},expression:"form.continueCollectStatus"}}),_c('PickEnum',{attrs:{"show":_vm.form.continueCollectStatusShow,"columns":['舆情不催收', '其他情况不催收'].map((it) => ({
              label: it,
              value: it,
            }))},on:{"update:show":function($event){return _vm.$set(_vm.form, "continueCollectStatusShow", $event)},"handleConfirm":(v) => _vm.$set(_vm.form, 'continueCollectStatus', v.value)}}),_c('van-field',{attrs:{"rows":"2","autosize":"","label":"跟进措施","type":"textarea","maxlength":"150","placeholder":"请输入跟进措施","show-word-limit":""},model:{value:(_vm.form.measures),callback:function ($$v) {_vm.$set(_vm.form, "measures", $$v)},expression:"form.measures"}})],1)]),_c('div',{staticClass:"h-128"}),_c('div',{staticClass:"fixed left-0 bottom-0 w-100vw bg-white py-8 px-16"},[_c('van-button',{attrs:{"loading":_vm.pending,"size":"large","color":"#1890FF","type":"info","native-type":"submit"}},[_vm._v("保存 ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }