<template>
  <div class="new-reminder">
    <van-form @submit="submit" ref="loginForm">
      <div class="new-reminder-content">
        <div class="block case-info">
          <div class="block-title">案件信息</div>
          <div class="kv-info-wrp">
            <div
              v-for="(item, index) in [
                {
                  label: '订单编号',
                  value: form.bizNo,
                },
                {
                  label: '客户名称',
                  value: form.customerName,
                },
              ]"
              :key="index"
              class="kv"
            >
              <div class="k">{{ item.label }}：</div>
              <div class="v">{{ item.value || "-" }}</div>
            </div>
          </div>
        </div>

        <div class="block somn">
          <div class="block-title">催记信息</div>

          <van-field
            v-model="form.name"
            label="联系人姓名"
            placeholder="请输入"
            input-align="right"
            required
            maxlength="32"
            :rules="rules.name"
          />

          <van-field
            v-model="form.mobile"
            label="联系电话"
            placeholder="请输入"
            maxlength="11"
            input-align="right"
            required
            :rules="rules.mobile"
          />

          <van-field
            v-model="form.relationshipName"
            label="联系人关系"
            placeholder="请选择"
            :rules="rules.relationshipName"
            readonly
            required
            is-link
            input-align="right"
            @click="$set(form, 'relationshipShow', true)"
          />

          <!-- 联系人关系 弹窗 -->
          <PickEnum
            :show.sync="form.relationshipShow"
            :columns="
              relationshipList.map((it) => ({
                label: it.displayName,
                value: it.value,
              }))
            "
            @handleConfirm="
              (v) => {
                $set(form, 'relationshipName', v.label);
                $set(form, 'relationship', v.value);
              }
            "
          />

          <van-field
            v-model="form.collectionResultName"
            label="催收结果"
            placeholder="请选择"
            :rules="rules.collectionResultName"
            readonly
            required
            is-link
            input-align="right"
            @click="$set(form, 'collectionResultShow', true)"
          />

          <!-- 催收结果 弹窗 -->
          <van-popup v-model="form.collectionResultShow" position="bottom">
            <van-cascader
              :field-names="{
                text: 'collectionResultValue',
                value: 'collectionResultKey',
                children: 'childs',
              }"
              :options="resultListFilterChilds"
              @finish="
                (v) => {
                  $set(
                    form,
                    'collectionResultName',
                    v.selectedOptions[v.tabIndex].collectionResultValue
                  );
                  $set(
                    form,
                    'collectionResultKey',
                    v.selectedOptions[v.tabIndex].collectionResultKey
                  );
                  $set(form, 'collectionResultShow', false);
                }
              "
              @close="$set(form, 'collectionResultShow', false)"
            />

            <!--  -->
          </van-popup>

          <van-field
            v-model="form.settleStatus"
            label="是否结清"
            placeholder="请选择"
            :rules="rules.settleStatus"
            required
            readonly
            is-link
            input-align="right"
            @click="$set(form, 'settleStatusShow', true)"
          />

          <!-- 催收结果 弹窗 -->
          <PickEnum
            :show.sync="form.settleStatusShow"
            :columns="
              ['未结清', '已结清', '已还期款'].map((it) => ({
                label: it,
                value: it,
              }))
            "
            @handleConfirm="(v) => $set(form, 'settleStatus', v.value)"
          />

          <van-field
            v-model="form.repaymentPossibilityStr"
            label="还款意愿"
            placeholder="请选择"
            :rules="rules.repaymentPossibility"
            readonly
            required
            is-link
            input-align="right"
            @click="$set(form, 'repaymentPossibilityShow', true)"
          />

          <van-field
            v-model="form.legalSuggestionStr"
            label="诉讼建议"
            placeholder="请选择"
            :rules="rules.legalSuggestion"
            readonly
            required
            is-link
            input-align="right"
            @click="$set(form, 'legalSuggestionShow', true)"
          />

          <!-- 还款意愿 弹窗 -->
          <PickEnum
            :show.sync="form.repaymentPossibilityShow"
            :columns="
              repaymentPossibilityEnum.map((it) => ({
                label: it.displayName,
                value: it.value,
              }))
            "
            @handleConfirm="
              (v) => {
                $set(form, 'repaymentPossibilityStr', v.label);
                $set(form, 'repaymentPossibility', v.value);
              }
            "
          />
          <!-- 诉讼建议 弹窗 -->
          <PickEnum
            :show.sync="form.legalSuggestionShow"
            :columns="
              legalSuggestionEnum.map((it) => ({
                label: it.displayName,
                value: it.value,
              }))
            "
            @handleConfirm="
              (v) => {
                $set(form, 'legalSuggestionStr', v.label);
                $set(form, 'legalSuggestion', v.value);
              }
            "
          />

          <van-field
            v-model="form.continueCollectStatus"
            label="是否继续催收"
            placeholder="请选择"
            :rules="rules.continueCollectStatus"
            readonly
            is-link
            input-align="right"
            @click="$set(form, 'continueCollectStatusShow', true)"
          />

          <!-- 催收结果 弹窗 -->
          <PickEnum
            :show.sync="form.continueCollectStatusShow"
            :columns="
              ['舆情不催收', '其他情况不催收'].map((it) => ({
                label: it,
                value: it,
              }))
            "
            @handleConfirm="(v) => $set(form, 'continueCollectStatus', v.value)"
          />

          <van-field
            v-model="form.measures"
            rows="2"
            autosize
            label="跟进措施"
            type="textarea"
            maxlength="150"
            placeholder="请输入跟进措施"
            show-word-limit
          />

          <!--  -->
        </div>
      </div>

      <!-- 留白 -->
      <div class="h-128"></div>

      <!-- 保存 -->
      <div class="fixed left-0 bottom-0 w-100vw bg-white py-8 px-16">
        <van-button
          :loading="pending"
          size="large"
          color="#1890FF"
          type="info"
          native-type="submit"
          >保存
        </van-button>
      </div>

      <!--  -->
    </van-form>

    <!--  -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { isMobile } from "@/utils/tool";

import { collectionAddCollectionPost } from "@/api";

export default {
  name: "NewReminder",
  components: {
    PickEnum: () => import("@/components/PickEnum"),
  },
  data() {
    return {
      pending: false,
      form: {},

      //
    };
  },
  computed: {
    ...mapState("enums", [
      "relationshipList",
      "repaymentPossibilityEnum",
      "legalSuggestionEnum",
    ]),
    ...mapGetters("enums", ["resultListFilterChilds"]),

    rules() {
      return {
        name: [{ required: true, message: "请输入" }],
        mobile: [
          { required: true, message: "请输入手机号" },
          {
            validator: (v) => {
              return isMobile(v);
            },
            message: "请输入正确的手机号",
          },
        ],
        relationshipName: [{ required: true, message: "请选择" }],
        collectionResultName: [{ required: true, message: "请选择" }],
        settleStatus: [{ required: true, message: "请选择" }],
        repaymentPossibility: [{ required: true, message: "请选择" }],
        legalSuggestion: [{ required: true, message: "请选择" }],
      };
    },

    //
  },
  created() {
    this.form = JSON.parse(sessionStorage.newReminderItem || "{}");

    //
  },
  methods: {
    // 提交
    async submit() {
      try {
        this.pending = true;

        await collectionAddCollectionPost(this.form);

        this.$toast.success("操作成功");

        await new Promise((resolve) => setTimeout(() => resolve(), 1500));

        this.$router.back();

        //
      } catch (err) {
        this.$toast(err);
      } finally {
        this.pending = false;
      }

      //
    },

    //
  },
};
</script>

<style lang="scss" scoped>

.new-reminder {
  // width: 100%;
  background: #f5f7f9;
  // height: 100vh;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 284px;
    z-index: 0;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, #1890ff, #f5f7f9);
  }
  .new-reminder-content {
    position: relative;
    // z-index: 10;
    /* height: 100vh;
    width: 100%; */
    display: flex;
    flex-direction: column;
    .block {
      width: 702px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      &.case-info {
        margin-top: 32px;
      }
      &.somn {
        margin-top: 24px;
        border-top: 1px solid #f2f5f5;
      }
      .kv-info-wrp {
        margin: 0 24px;
        border-top: 1px solid #f2f5f5;
        .kv {
          line-height: 76px;
          display: flex;
          font-size: 28px;
          .k {
            color: #666666;
          }
          .v {
            color: #333333;
          }
        }
      }
    }
  }
}

</style>
